/* For ease all css classes are defined here.
   May be spread out later if the app grows in size
*/

:root {
  --gray: #353541;
  --orange: #F18544;
  --black: #040405d6;
  --lightblack: #7f7f8fd6;
  --white: antiquewhite;
}

html, .App {
  background-color: var(--gray);
  padding: 0;
  margin: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.convo-section {
  margin: 5rem 0 0 0;
  width: 80vw;
  height: calc(100vh - 15rem); 
  overflow: scroll;
}

.convo-bubble {
  width: 70%;
  border-radius: 20px;
  min-height: 8vh;
}

.convo-content {
  margin: 1%;
}

.query-input {
  height: 60px;
  position: fixed;
  bottom: 5vh;
  width: 80vw;
}

.query-text {
  background-color: var(--orange);
}

.response-text {
  background-color: var(--white);
}

.notes-text {
  background-color: rgb(225, 176, 107);
  min-height: 100px;
}

.interview-bubble {
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  border-bottom-right-radius: 0;
}

.file-upload-info {
  background-color: var(--black);
  color: var(--white);
}

.customCard {
  height: 50vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  background-color: lightslategray;
}

.cardIcon {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.cardInfo {
  margin: auto;
  text-align: center;
}

.customCard:hover {
  cursor: pointer;
  background-color: antiquewhite;
}

.JDTextArea {
  width: 80%;
  height: 400px;
  background-color: lightslategray;
  border-radius: 2%;
}

.noResultsMessage {
  width: 75vw;
  margin-top: 8vh;
  text-align: center;
  position: absolute;
}

.signInBtn {
  background-color: #F18544;
  padding: 30px;
  font-size: xx-large;
  border-radius: 10px;
}

.signInFlex {
  height: 100vh;
}

.blob-title {
  font-size: x-large;
  color: rgb(234, 228, 220);
  font-weight: bolder;
}

.separator {
  border-top: 3px dashed var(--white);
}